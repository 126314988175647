import React, {useEffect, useState} from 'react';
import { BlockPicker } from 'react-color';
import '../scss/colourPicker.scss'
interface pickerProps{
    propertyName: string;
    cssProperty:string;
    colourProperty:any;
    default:string;
}


const ColourPicker: React.FC<pickerProps> = (props) => {
    const propID = props.propertyName.replace(/ /g, '');
    const [colour, setColour] = useState(props.default)
    const [pickerVisible, setPickerVisible] = useState(false);
    const [output, setOutput] = useState(false);
    const [colourLayer, setColourLayer]=useState('5');

    props.colourProperty(colour);

    //Colour Picker Change
    const handleChangeComplete = (color: any) => {
        setColour(color.hex);
        document.documentElement.style.setProperty(
            props.cssProperty,
            color.hex
        );

    };

    const handleClose = () =>{
        setPickerVisible(false);
        setColourLayer('5');

    }

    //Show / Hide Colour Picker
    function colourPickerVisible(){
        setPickerVisible((current) => !current);

        if(!pickerVisible){
            setColourLayer('50');
        }
    }

    function resetColour() {
        setColour(props.default);
        document.documentElement.style.setProperty(
            props.cssProperty,
            props.default
        );
    }


    //Show Hide Property in Preview
    const showHide = (color: any) => {
        const visibility = document.getElementById(propID) as HTMLInputElement;

        if(!visibility.checked){
            document.documentElement.style.setProperty(
                props.cssProperty,
                props.default
            );
            setOutput(false);
            setColour(props.default);
        }else{
            document.documentElement.style.setProperty(
                props.cssProperty,
                colour
            );
            setOutput(true);
        }

        return(
            showHide
        )
    }
    return (
        <div className='m-colourpicker' style={{zIndex : colourLayer}}>
            <div className="e-title">
                {props.propertyName}
            </div>
                <div className="selector">
                    <div className="e-reset" onClick={resetColour}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z"/></svg>
                    </div>
                    <div className="e-selectedcolour">
                        <div className="current" onClick={colourPickerVisible}>
                            <div className="icon"
                                 style={{
                                     background : colour
                                 }}
                            />

                            <div className="value">
                                {colour}
                            </div>
                        </div>

                        {pickerVisible &&
                            <>
                                <div className='popup'>
                                    <BlockPicker
                                        color={colour}
                                        onChange={handleChangeComplete}
                                        triangle='hide'
                                        colors={['#FFFFFF','#000000', '#F47373', '#37D67A', '#2CCCE4', '#FFE667', '#ff8a65', '#ba68c8', '#55cbcd', '#f3b0c3']}
                                    />
                                </div>
                                <div className="offclick" onClick={handleClose}></div>
                            </>
                        }
                    </div>
                </div>

        </div>
    );

}

export default ColourPicker;
