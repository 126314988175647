import React, {ChangeEvent, useEffect, useId, useState} from 'react';
import '../scss/rangeSlider.scss'
interface rangeProps{
    propertyName: string;
    cssProperty:string;
    maxAmount:number;
    minAmount:number;
    steps:number;
    value:string;
    rangeProperty:any;
}
const RangeSlider: React.FC<rangeProps> = (props) => {
    const [rangeValue, setRangeValue] = useState(props.value);
    const propID = props.propertyName.replace(/ /g, '');
    const CSSProperty = props.cssProperty;

    useEffect(() => {
        setRangeValue(rangeValue);
        props.rangeProperty(rangeValue);

        if(CSSProperty.includes('Weight')){
            document.documentElement.style.setProperty(
                props.cssProperty,
                rangeValue
            );
        }else if(CSSProperty.includes('Perspective')){
            document.documentElement.style.setProperty(
                props.cssProperty,
                rangeValue + 'deg'
            );
        } else{
            document.documentElement.style.setProperty(
                props.cssProperty,
                rangeValue + 'px'
            );
        }
    }, [rangeValue]);

    function updateInput(event:ChangeEvent<HTMLInputElement>){
        const inputTarget = event.target;
        setRangeValue(inputTarget.value);
    }

    function reset(event:any){
        if(event.detail === 2){
            setRangeValue(props.value);
        }
    }


    return(
        <div className="m-rangeslider">
            <div className="e-title">
                <label htmlFor={propID}>
                    {props.propertyName}
                </label>
            </div>
            <div className="e-slider" onClick={reset} title="Double Click to Reset">
                <input type="range"
                       id={propID}
                       value={rangeValue}
                       onInput={updateInput}
                       min={props.minAmount}
                       max={props.maxAmount}
                       step={props.steps}
                />
                <span className="value">
                    {rangeValue}{CSSProperty.includes('Weight') ? '' : 'px'}
                </span>
            </div>
        </div>
    )
}

export default RangeSlider;