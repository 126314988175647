import React, {useState} from 'react';
import '../scss/colourPicker.scss'

interface pickerProps{
    name: string;
    streamElementsCode:string;
    streamLabsCode:string;
    image:string
}

const PresetItem: React.FC<pickerProps> = (props) => {
    const [copied, setCopied] = useState(false);

    const copyCode = (codeID:string) =>{
        let codeElement: string = document.getElementById(codeID)?.innerText as string;
        navigator.clipboard.writeText(codeElement)

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }


    return (
        <div className="e-item">
            <div className="inner">
                <div className={copied ? "meta copied" : "meta"}>
                    <span>
                       {props.name}
                    </span>

                    <div className="tags">
                        <div className="codeitem streamelements" onClick={() => copyCode(props.name + '-se')}>
                            <img src="logo-se.png" alt=""/>
                            <div className="code" id={`${props.name}-se`}>
                                {props.streamElementsCode}
                            </div>
                        </div>
                        <div className="codeitem streamlabs" onClick={() => copyCode(props.name + '-sl')}>
                            <img src="logo-sl.png" alt=""/>
                            <div className="code" id={`${props.name}-sl`}>
                                {props.streamLabsCode}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src={props.image} alt=""/>
                </div>
            </div>
        </div>
    );

}

export default PresetItem;
