import React, {useState} from 'react';
import './scss/styles.scss'
import ColourPicker from './components/colourPicker';
import RangeSlider from "./components/rangeSlider";
import CustomFont from "./components/customFont";
import Presets from "./components/presets";
import Footer from "./components/Footer"
import logo from "./components/images/ChatStyler-Image.png"
import Panel from "./components/images/ChatStyler-Panel.png"
import AitumLogo from "./components/images/AitumLogo.png"
import AitumSnapshot from "./components/images/AitumSnapshot.png"

function App() {
    const [badgeOutput, setBadgeOutput] = useState('inline-flex');
    const [fadeAnimation, setFadeAnimation] = useState('none');
    const [opacity, setOpacity] = useState('1');

    const [spacing, setSpacing] = useState('5');
    const [margins, setMargins] = useState('10');
    const [xPerspective, setXPerspective] = useState('0');
    const [yPerspective, setYPerspective] = useState('0');
    const [zPerspective, setZPerspective] = useState('0');

    //set output for user properties in code
    const [userFont, setUserFont] = useState('Roboto');
    const [userBackground, setUserBackground] = useState('none');
    const [userColour, setUserColour] = useState('#ff0000');
    const [userSize, setUserSize] = useState('16');
    const [userWeight, setUserWeight] = useState('400');
    const [userBorderRadius, setUserBorderRadius] = useState('0');
    const [userPadding, setUserPadding] = useState('0 0');

    const userFontCode = userFont.replace(/ /g, '+');

    //set output for chat message properties in code
    const [chatFont, setChatFont] = useState('Roboto');
    const [chatBackground, setChatBackground] = useState('none');
    const [chatColour, setChatColour] = useState('#444444');
    const [chatSize, setChatSize] = useState('16');
    const [chatWeight, setChatWeight] = useState('400');
    const [chatBorderRadius, setChatBorderRadius] = useState('0');
    const [chatPadding, setChatPadding] = useState('0 0');

    const chatFontCode = chatFont.replace(/ /g, '+');

    const [copiedSE, setCopiedSE] = useState(false);
    const [copiedSL, setCopiedSL] = useState(false);

    function badgeCheck() {
        const badgeCheckbox = document.getElementById("badgeCheck") as HTMLInputElement;

        if (badgeCheckbox.checked) {
            document.documentElement.style.setProperty(
                '--badgeCheck',
                'none'
            );
            setBadgeOutput('none');
        } else {
            document.documentElement.style.setProperty(
                '--badgeCheck',
                'inline-flex'
            );
            setBadgeOutput('inline-flex');
        }

        return (
            badgeOutput
        )
    }

    function fadeCheck() {
        const fadeCheckbox = document.getElementById("fadeAnimation") as HTMLInputElement;

        if (fadeCheckbox.checked) {
            document.documentElement.style.setProperty(
                '--fade',
                'fade'
            );
            setFadeAnimation('fade');

            //Opacity
            document.documentElement.style.setProperty(
                '--opacity',
                '0'
            );
            setOpacity('0');
        } else {
            document.documentElement.style.setProperty(
                '--fade',
                'none'
            );
            setFadeAnimation('none');

            //Opacity
            document.documentElement.style.setProperty(
                '--opacity',
                '1'
            );
            setOpacity('1');
        }

        return (
            fadeAnimation + opacity
        )
    }


    const copySE = () => {
        let streamElementsCode: string = document.getElementById('streamElementsCode')?.innerText as string;
        navigator.clipboard.writeText(streamElementsCode)

        setCopiedSE(true);
        setTimeout(() => {
            setCopiedSE(false);
        }, 2000);
    }

    const copySL = () => {
        let streamLabsCode: string = document.getElementById('streamLabsCode')?.innerText as string;
        navigator.clipboard.writeText(streamLabsCode)

        setCopiedSL(true);
        setTimeout(() => {
            setCopiedSL(false);
        }, 2000);
    }

    return (
        <>
            <header className="l-intro">
                <div className="container">
                    <div className="e-image">
                        <img src={logo} alt=""/>
                    </div>
                    <h1>Chat Styler</h1>

                    <p className='preamble'>
                        Welcome to the chat styler, create the look that <strong>YOU</strong> want.
                    </p>
                    <p>
                        Using the tool below, customise your chat overlay to your theme on your stream. Once you have
                        setup the chat within your editor (StreamElements / StreamLabs) and created your browser source
                        in OBS Studio, click the button to copy the code and paste it into your OBS browser source 'Custom CSS'.
                    </p>
                    <p>
                        <small><em>Perspective (X, Y, Z) may differ depending on browser source & chat widget / overlay width.</em></small>
                    </p>
                </div>
            </header>
            <div className="container">

                <div className="row">
                    <div className="l-left">
                        <div className="e-form">
                            <h2>General</h2>
                            <div className="form-row">
                                <div className='toggle'>
                                    <input type="checkbox" id="badgeCheck" onChange={badgeCheck}/>
                                    <label htmlFor="badgeCheck">Hide Badges</label>
                                </div>
                                <div className='toggle'>
                                    <input type="checkbox" id="fadeAnimation" onChange={fadeCheck}/>
                                    <label htmlFor="fadeAnimation">Fade</label>
                                </div>
                                <RangeSlider propertyName="Spacing" cssProperty="--spacing" maxAmount={20}
                                             minAmount={-10}
                                             steps={1} value='5' rangeProperty={setSpacing}/>
                                <RangeSlider propertyName="Margins" cssProperty="--margins" maxAmount={100}
                                             minAmount={-25}
                                             steps={1} value='10' rangeProperty={setMargins}/>
                                <RangeSlider propertyName="Rotate X Axis" cssProperty="--xPerspective" maxAmount={65}
                                             minAmount={-65}
                                             steps={1} value='0' rangeProperty={setXPerspective}/>
                                <RangeSlider propertyName="Rotate Y Axis" cssProperty="--yPerspective" maxAmount={65}
                                             minAmount={-65}
                                             steps={1} value='0' rangeProperty={setYPerspective}/>
                                <RangeSlider propertyName="Rotate Z Axis" cssProperty="--zPerspective" maxAmount={65}
                                             minAmount={-65}
                                             steps={1} value='0' rangeProperty={setZPerspective}/>
                            </div>

                            <h2>Username</h2>
                            <div className="form-row">
                                <CustomFont propertyName="Username Font" cssProperty='--userFont'
                                            fontProperty={setUserFont}
                                            fontWeight={userWeight}/>
                                <ColourPicker propertyName="Username Color" cssProperty='--userColor' default='#ff0000'
                                              colourProperty={setUserColour}/>
                                <ColourPicker propertyName="Username Background" cssProperty='--userBackground'
                                              default='transparent' colourProperty={setUserBackground}/>
                                <RangeSlider propertyName="Font Size" cssProperty="--userFontSize" maxAmount={40}
                                             minAmount={12} steps={1} value='16' rangeProperty={setUserSize}/>
                                <RangeSlider propertyName="Font Weight" cssProperty="--userWeight" maxAmount={800}
                                             minAmount={300} steps={100} value='400' rangeProperty={setUserWeight}/>
                                <RangeSlider propertyName="Border Radius" cssProperty="--userBorderRadius"
                                             maxAmount={30}
                                             minAmount={0} steps={1} value='0' rangeProperty={setUserBorderRadius}/>
                                <RangeSlider propertyName="Padding" cssProperty="--userPadding" maxAmount={30}
                                             minAmount={0}
                                             steps={1} value='0' rangeProperty={setUserPadding}/>
                            </div>

                            <h2>Chat Message</h2>
                            <div className="form-row">
                                <CustomFont propertyName="Message Font" cssProperty='--chatFont'
                                            fontProperty={setChatFont}
                                            fontWeight={chatWeight}/>
                                <ColourPicker propertyName="Message Color" cssProperty='--chatColour' default='#444444'
                                              colourProperty={setChatColour}/>
                                <ColourPicker propertyName="Message Background" cssProperty='--chatBackground'
                                              default='transparent' colourProperty={setChatBackground}/>
                                <RangeSlider propertyName="Font Size" cssProperty="--chatFontSize" maxAmount={40}
                                             minAmount={12} steps={1} value='16' rangeProperty={setChatSize}/>
                                <RangeSlider propertyName="Font Weight" cssProperty="--chatWeight" maxAmount={800}
                                             minAmount={300} steps={100} value='400' rangeProperty={setChatWeight}/>
                                <RangeSlider propertyName="Border Radius" cssProperty="--chatBorderRadius"
                                             maxAmount={30}
                                             minAmount={0} steps={1} value='0' rangeProperty={setChatBorderRadius}/>
                                <RangeSlider propertyName="Padding" cssProperty="--chatPadding" maxAmount={30}
                                             minAmount={0}
                                             steps={1} value='0' rangeProperty={setChatPadding}/>
                            </div>
                        </div>
                    </div>
                    <div className="l-right">
                        <div className="row">
                            <div className="l-examples">
                                <div className="box light">
                                    <div className="inner">
                                        <div id="wrapper">
                                            <div className="twitch-chat layout-align-end-start layout-column">
                                                <div className="chat-line flex-none">
                                                <span className="badges">
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3"
                                                         className="badge"/>
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/3"
                                                         className="badge"/>
                                                </span>
                                                    <span className="username">
                                                    Username
                                                </span>
                                                    <span className="colon"></span>
                                                    <span className="message">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis bibendum nisi, quis aliquet lectus feugiat non. Suspendisse ac placerat ex. Donec tempus, mauris lacinia tincidunt molestie, nunc nisl ullamcorper risus, quis posuere augue leo eu tortor.
                                                </span>
                                                </div>
                                                <div className="chat-line flex-none">
                                                <span className="badges">
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3"
                                                         className="badge"/>
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/3"
                                                         className="badge"/>
                                                </span>
                                                    <span className="username">
                                                    Username
                                                </span>
                                                    <span className="colon"></span>
                                                    <span className="message">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis bibendum nisi, quis aliquet lectus feugiat non. Suspendisse ac placerat ex. Donec tempus, mauris lacinia tincidunt molestie, nunc nisl ullamcorper risus, quis posuere augue leo eu tortor.
                                                </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box dark">
                                    <div className="inner">
                                        <div id="wrapper">
                                            <div className="twitch-chat layout-align-end-start layout-column">
                                                <div className="chat-line flex-none">
                                                <span className="badges">
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3"
                                                         className="badge"/>
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/3"
                                                         className="badge"/>
                                                </span>
                                                    <span className="username">
                                                    Username
                                                </span>
                                                    <span className="colon"></span>
                                                    <span className="message">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis bibendum nisi, quis aliquet lectus feugiat non. Suspendisse ac placerat ex. Donec tempus, mauris lacinia tincidunt molestie, nunc nisl ullamcorper risus, quis posuere augue leo eu tortor.
                                                </span>
                                                </div>
                                                <div className="chat-line flex-none">
                                                <span className="badges">
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3"
                                                         className="badge"/>
                                                    <img alt=''
                                                         src="https://static-cdn.jtvnw.net/badges/v1/bd444ec6-8f34-4bf9-91f4-af1e3428d80f/3"
                                                         className="badge"/>
                                                </span>
                                                    <span className="username">
                                                    Username
                                                </span>
                                                    <span className="colon"></span>
                                                    <span className="message">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis bibendum nisi, quis aliquet lectus feugiat non. Suspendisse ac placerat ex. Donec tempus, mauris lacinia tincidunt molestie, nunc nisl ullamcorper risus, quis posuere augue leo eu tortor.
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l-buttons">
                                <button className='btn streamelements' onClick={copySE}>
                                    <img src="logo-se.png" alt=""/>
                                    StreamElements Overlay

                                    <span className={copiedSE ? "e-copy copied" : "e-copy"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                                </button>
                            </div>
                            <div className="l-code">
                                <div className="inner">
                                    <h4
                                        onClick={copySE}
                                    >
                                        Browser Source CSS Override Code
                                    </h4>
                                    <div className="css-edit" id='streamElementsCode'>
                                        @import
                                        url('https://fonts.googleapis.com/css2?family={userFontCode}&family={chatFontCode}&display=swap');
                                        @keyframes fade {`{
                                        0% {
                                            opacity: 0;
                                        }
                                        100% {
                                            opacity: 1;
                                        }
                                    }`}
                                        .overlay, .widget, .twitch-chat, .chat-line{`{
                                            overflow:visible!important;
                                        }`}
                                        .twitch-chat{`{
                                            perspective:1000px;
                                        }`}
                                        .chat-line {`{
                                        margin:` + margins + `px 0;
                                        display:flex;
                                        flex-wrap: wrap;
                                        animation: ` + fadeAnimation + ` 0.5s ease-in-out forwards;
                                        opacity:` + opacity + `;
                                        transform-style: preserve-3d;
                                        transform:rotateX(` + xPerspective + `deg) rotateY(` + yPerspective + `deg) rotateZ(` + zPerspective + `deg);
                                    }`}


                                        .badges{`{
                                        display:` + badgeOutput + `;
                                        align-items: flex-end; 
                                        margin-right:5px;
                                    }`}

                                        .chat-line .username {`{
                                        font-family:'` + userFont + `', sans-serif;
                                        font-weight:` + userWeight + `;
                                        font-size:` + userSize + `px;
                                        line-height:` + Math.floor(Number(userSize) * 1.2) + `px;
                                        border-radius:` + userBorderRadius + `px;
                                        color:` + userColour + ` !important;
                                        background:` + userBackground + `;
                                        padding:` + Math.floor(Number(userPadding) * 0.4) + `px ` + userPadding + `px;
                                        z-index:20;
                                        
                                    }`}

                                        .chat-line .message {`{
                                    font-family:'` + chatFont + `', sans-serif;
                                    font-weight:` + chatWeight + `;
                                    font-size:` + chatSize + `px;
                                    line-height:` + Math.floor(Number(chatSize) * 1.2) + `px;
                                    border-radius:` + chatBorderRadius + `px;
                                    color:` + chatColour + ` !important;
                                    background:` + chatBackground + `;
                                    margin-top:` + spacing + `px;
                                    padding:` + Math.floor(Number(chatPadding) * 0.8) + `px ` + chatPadding + `px;
                                    width:100%;
                                  }`}

                                        .colon{`{
                                  display:none;
                                  }`}

                                    </div>
                                </div>
                            </div>
                            <div className="l-buttons">
                                <button className='btn streamlabs' onClick={copySL}>
                                    <img src="logo-sl.png" alt=""/>
                                    StreamLabs Overlay

                                    <span className={copiedSL ? "e-copy copied" : "e-copy"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                </span>
                                </button>
                            </div>
                            <div className="l-code">
                                <div className="inner">
                                    <h4>Browser Source CSS Override Code</h4>

                                    <div className="css-edit" id='streamLabsCode'>

                                        @import
                                        url('https://fonts.googleapis.com/css2?family={userFontCode}&family={chatFontCode}&display=swap');

                                        @keyframes fade {`{
                                        0% {
                                            opacity: 0;
                                        }
                                        100% {
                                            opacity: 1;
                                        }
                                    }`}
                                        #log{`{
                                        text-shadow:none !important;
                                        perspective: 1000px;
                                    }`}

                                        #log {`>`} div {`{
                                        display: flex;
                                        flex-direction: column;
                                        margin:` + margins + `px 0;
                                        animation: ` + fadeAnimation + ` 0.5s ease-in-out forwards !important;
                                        opacity:` + opacity + `;
                                        transform-style: preserve-3d;
                                        transform:rotateX(` + xPerspective + `deg) rotateY(` + yPerspective + `deg) rotateZ(` + zPerspective + `deg);
                                    }`}

                                        #log .meta{`{
                                        width:auto !important;
                                        text-align:left;
                                        position: relative;
                                        z-index: 20;
                                        overflow:visible !important;
                                    }`}
                                        #log .badges{`{
                                        display:` + badgeOutput + ` !important;
                                        align-items: flex-end; 
                                        margin-right:5px;
                                    }`}
                                        #log .name {`{
                                        font-family:'` + userFont + `', sans-serif;
                                        font-weight:` + userWeight + `;
                                        font-size:` + userSize + `px;
                                        line-height:` + Math.floor(Number(userSize) * 1.2) + `px;
                                        border-radius:` + userBorderRadius + `px;
                                        color:` + userColour + ` !important;
                                        background:` + userBackground + `;
                                        padding:` + Math.floor(Number(userPadding) * 0.4) + `px ` + userPadding + `px;
                                        z-index:20;
                                        width:auto!important;
                                        overflow:visible!important;
                                        margin-left:0;
                                    }`}
                                        #log .message {`{
                                        font-family:'` + chatFont + `', sans-serif;
                                        font-weight:` + chatWeight + ` !important;
                                        font-size:` + chatSize + `px;
                                        line-height:` + Math.floor(Number(chatSize) * 1.2) + `px;
                                        border-radius:` + chatBorderRadius + `px;
                                        color:` + chatColour + ` !important;
                                        background:` + chatBackground + `;
                                        margin-top:` + spacing + `px;
                                        padding:` + Math.floor(Number(chatPadding) * 0.8) + `px ` + chatPadding + `px;
                                        width:100%;
                                        z-index:10;
                                    }`}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Presets />
            <section className="m-support">
                <div className="container">
                    <div className="row centre-v">
                        <div className="panel">
                            <div className="e-panelwrapper">
                                <img src={Panel} alt=""/>
                            </div>
                        </div>
                        <div className="text">
                            <h2>Support</h2>

                            <p>If you wish to support the generator, you can do so by saving and adding this panel to your profile.</p>
                            <p>
                                <a href="https://streamelements.com/colinhorn/tip" target="_blank" className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"/></svg>
                                    Send Some Love
                                </a>
                            </p>
                        </div>

                        <div className="panel">
                            <img src={AitumSnapshot} className="aitum-snapshot" alt=""/>
                        </div>
                        <div className="text">
                            <img src={AitumLogo} alt=""/>

                            <p>Need to automate your stream? Create fun channel point redemptions? Check out Aitum. Take your stream to the next level!</p>
                            <p>
                                <a href="https://www.aitum.tv" target="_blank" className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
                                        <polygon className="st0" points="426.47,73.24 926.46,573.22 926.46,73.24 "/>
                                        <polygon className="st0" points="73.54,719.33 573.53,219.35 73.54,219.35 "/>
                                        <polygon className="st0" points="926.46,573.22 219.37,573.22 572.92,926.76 "/>
                                    </svg>
                                    aitum.tv Website
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default App;
